@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;500;600&display=swap');
$fontColor: #3C3C3B;
$red: #e0152d;
html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Work Sans', sans-serif;

  color: $fontColor;
}

main h1 {
  text-align: center;
}

.color-red {
  color: $red;
}
a {
  text-decoration: none;
  color: inherit;
}