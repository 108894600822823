/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;500;600&display=swap");
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after {
  content: "";
  content: none;
}

q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* ==========================================================================
   Normalize.scss settings
   ========================================================================== */
/**
 * Includes legacy browser support IE6/7
 *
 * Set to false if you want to drop support for IE6 and IE7
 */
/* Base
   ========================================================================== */
/**
 * 1. Set default font family to sans-serif.
 * 2. Prevent iOS and IE text size adjust after device orientation change,
 *    without disabling user zoom.
 * 3. Corrects text resizing oddly in IE 6/7 when body `font-size` is set using
 *  `em` units.
 */
html {
  font-family: sans-serif; /* 1 */
  -ms-text-size-adjust: 100%; /* 2 */
  -webkit-text-size-adjust: 100%; /* 2 */
}

/**
 * Remove default margin.
 */
body {
  margin: 0;
}

/* HTML5 display definitions
   ========================================================================== */
/**
 * Correct `block` display not defined for any HTML5 element in IE 8/9.
 * Correct `block` display not defined for `details` or `summary` in IE 10/11
 * and Firefox.
 * Correct `block` display not defined for `main` in IE 11.
 */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}

/**
 * 1. Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 * 2. Normalize vertical alignment of `progress` in Chrome, Firefox, and Opera.
 */
audio,
canvas,
progress,
video {
  display: inline-block; /* 1 */
  vertical-align: baseline; /* 2 */
}

/**
 * Prevents modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */
audio:not([controls]) {
  display: none;
  height: 0;
}

/**
 * Address `[hidden]` styling not present in IE 8/9/10.
 * Hide the `template` element in IE 8/9/10/11, Safari, and Firefox < 22.
 */
[hidden],
template {
  display: none;
}

/* Links
   ========================================================================== */
/**
 * Remove the gray background color from active links in IE 10.
 */
a {
  background-color: transparent;
}

/**
 * Improve readability of focused elements when they are also in an
 * active/hover state.
 */
a:active, a:hover {
  outline: 0;
}

/* Text-level semantics
   ========================================================================== */
/**
 * Address styling not present in IE 8/9/10/11, Safari, and Chrome.
 */
abbr[title] {
  border-bottom: 1px dotted;
}

/**
 * Address style set to `bolder` in Firefox 4+, Safari, and Chrome.
 */
b,
strong {
  font-weight: bold;
}

/**
 * Address styling not present in Safari and Chrome.
 */
dfn {
  font-style: italic;
}

/**
 * Address variable `h1` font-size and margin within `section` and `article`
 * contexts in Firefox 4+, Safari, and Chrome.
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

/**
 * Addresses styling not present in IE 8/9.
 */
mark {
  background: #ff0;
  color: #000;
}

/**
 * Address inconsistent and variable font size in all browsers.
 */
small {
  font-size: 80%;
}

/**
 * Prevent `sub` and `sup` affecting `line-height` in all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

/* Embedded content
   ========================================================================== */
/**
 * 1. Remove border when inside `a` element in IE 8/9/10.
 * 2. Improves image quality when scaled in IE 7.
 */
img {
  border: 0;
}

/**
 * Correct overflow not hidden in IE 9/10/11.
 */
svg:not(:root) {
  overflow: hidden;
}

/* Grouping content
   ========================================================================== */
/**
 * Address margin not present in IE 8/9 and Safari.
 */
figure {
  margin: 1em 40px;
}

/**
 * Address differences between Firefox and other browsers.
 */
hr {
  box-sizing: content-box;
  height: 0;
}

/**
 * Contain overflow in all browsers.
 */
pre {
  overflow: auto;
}

/**
 * Address odd `em`-unit font size rendering in all browsers.
 * Correct font family set oddly in IE 6, Safari 4/5, and Chrome.
 */
code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

/* Forms
   ========================================================================== */
/**
 * Known limitation: by default, Chrome and Safari on OS X allow very limited
 * styling of `select`, unless a `border` property is set.
 */
/**
 * 1. Correct color not being inherited.
 *  Known issue: affects color of disabled elements.
 * 2. Correct font properties not being inherited.
 * 3. Address margins set differently in Firefox 4+, Safari, and Chrome.
 * 4. Improves appearance and consistency in all browsers.
 */
button,
input,
optgroup,
select,
textarea {
  color: inherit; /* 1 */
  font: inherit; /* 2 */
  margin: 0; /* 3 */
}

/**
 * Address `overflow` set to `hidden` in IE 8/9/10/11.
 */
button {
  overflow: visible;
}

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Firefox, IE 8/9/10/11, and Opera.
 * Correct `select` style inheritance in Firefox.
 */
button,
select {
  text-transform: none;
}

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *  and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *  `input` and others.
 * 4. Removes inner spacing in IE 7 without affecting normal text inputs.
 *  Known issue: inner spacing remains in IE 6.
 */
button,
html input[type=button],
input[type=reset],
input[type=submit] {
  -webkit-appearance: button; /* 2 */
  cursor: pointer; /* 3 */
}

/**
 * Re-set default cursor for disabled elements.
 */
button[disabled],
html input[disabled] {
  cursor: default;
}

/**
 * Remove inner padding and border in Firefox 4+.
 */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

/**
 * Address Firefox 4+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */
input {
  line-height: normal;
}

/**
 * 1. Address box sizing set to `content-box` in IE 8/9/10.
 * 2. Remove excess padding in IE 8/9/10.
 *  Known issue: excess padding remains in IE 6.
 */
input[type=checkbox],
input[type=radio] {
  box-sizing: border-box; /* 1 */
  padding: 0; /* 2 */
}

/**
 * Fix the cursor style for Chrome's increment/decrement buttons. For certain
 * `font-size` values of the `input`, it causes the cursor style of the
 * decrement button to change from `default` to `text`.
 */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  height: auto;
}

/**
 * 1. Address `appearance` set to `searchfield` in Safari and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari and Chrome.
 */
input[type=search] {
  -webkit-appearance: textfield; /* 1 */
  box-sizing: content-box; /* 2 */
}

/**
 * Remove inner padding and search cancel button in Safari and Chrome on OS X.
 * Safari (but not Chrome) clips the cancel button when the search input has
 * padding (and `textfield` appearance).
 */
input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * Define consistent border, margin, and padding.
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

/**
 * 1. Correct `color` not being inherited in IE 8/9/10/11.
 * 2. Remove padding so people aren't caught out if they zero out fieldsets.
 * 3. Corrects text not wrapping in Firefox 3.
 * 4. Corrects alignment displayed oddly in IE 6/7.
 */
legend {
  border: 0; /* 1 */
  padding: 0; /* 2 */
}

/**
 * Remove default vertical scrollbar in IE 8/9/10/11.
 */
textarea {
  overflow: auto;
}

/**
 * Don't inherit the `font-weight` (applied by a rule above).
 * NOTE: the default cannot safely be changed in Chrome and Safari on OS X.
 */
optgroup {
  font-weight: bold;
}

/* Tables
   ========================================================================== */
/**
 * Remove most spacing between table cells.
 */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

td,
th {
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Work Sans", sans-serif;
  color: #3C3C3B;
}

main h1 {
  text-align: center;
}

.color-red {
  color: #e0152d;
}

a {
  text-decoration: none;
  color: inherit;
}

.teaser-home {
  background: url("../img/home-teaser.png") no-repeat;
  background-size: cover;
  width: 100%;
  height: 80vh;
  display: flex;
}
.teaser-home .container {
  align-items: center;
  justify-content: flex-start;
  display: flex;
}
.teaser-home h1 {
  text-align: left;
  color: #fff;
  font-weight: 500;
  font-size: 4.25rem;
}
.teaser-home h1 span {
  font-size: 5rem;
}

.teaser {
  width: 100%;
  height: 490px;
  display: flex;
  background-size: cover !important;
}
.teaser.teaser-gallery {
  background: url("../img/gallery-teaser.png") no-repeat;
}
.teaser.teaser-contact {
  background: url("../img/contact-teaser.png") no-repeat;
}
.teaser.teaser-menu {
  background: url("../img/menu-teaser.png") no-repeat;
}
.teaser .container {
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display: flex;
}
.teaser h1 {
  color: #fff;
  font-size: 4.25rem;
  margin-bottom: 5px;
}
.teaser p {
  font-size: 1.5rem;
  text-align: center;
  color: #fff;
  padding-top: 20px;
}

.navbar {
  border-bottom: solid 1px #fff;
  position: absolute;
  width: 100%;
}
.navbar-nav {
  gap: 28px;
}

.nav-link {
  font-size: 16px;
  font-weight: 500;
  color: #fff;
}

.index-1 {
  height: 100vh;
  background: #fff9f1;
  overflow: hidden;
  position: relative;
}
.index-1 .coffee {
  background: url("../img/index-anim/coffee.png") no-repeat;
  background-size: cover;
  width: 350px;
  height: 350px;
  position: absolute;
  top: -20%;
  left: 7%;
}
.index-1 .plate {
  background: url("../img/index-anim/plate.png") no-repeat;
  background-size: cover;
  width: 265px;
  height: 558px;
  position: absolute;
  top: 30%;
  left: 0;
}
.index-1 .leaf {
  background: url("../img/index-anim/leaf.png") no-repeat;
  background-size: cover;
  width: 135px;
  height: 101px;
  position: absolute;
  bottom: 0;
  left: 22%;
}
.index-1 .toast-sandv {
  background: url("../img/index-anim/toast.png") no-repeat;
  background-size: cover;
  width: 319px;
  height: 558px;
  position: absolute;
  right: 0;
  top: 0;
}
.index-1 .ice-cream {
  background: url("../img/index-anim/icecream.png") no-repeat;
  background-size: cover;
  width: 495px;
  height: 293px;
  position: absolute;
  right: 10%;
  bottom: -6%;
}
.index-1 .text {
  width: 50%;
  margin: 0 auto;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.index-1 .text p {
  font-size: 1.5rem;
  line-height: 1.43;
  color: #3C3C3B;
  text-align: center;
}

.nav-pills .nav-link {
  color: #3C3C3B;
  font-size: 1.5rem;
  line-height: 1.2;
}
.nav-pills .nav-link.active {
  color: #e0152d !important;
  background-color: transparent !important;
}

footer {
  background: #f8f8f8;
  padding: 3rem 0 0;
}
footer .title {
  gap: 10px;
  font-size: 3rem;
  font-weight: 500;
  color: inherit;
  text-decoration: none;
}
footer .follow-text h3 {
  font-size: 3rem;
  font-weight: 500;
}
footer .follow-text p {
  font-size: 1rem;
  font-weight: 500;
  line-height: 3.01;
}

.foot {
  padding: 1.5rem 0;
  background: #fff;
}
.foot p {
  font-size: 12px;
  line-height: 1.2;
}

address {
  text-align: center;
  padding: 5rem 0;
}
address p {
  font-size: 1.5rem;
  line-height: 28px;
}

.contact-form {
  width: 100%;
  max-width: 380px;
}
.contact-form .head h4 {
  font-size: 1.5rem;
  line-height: 28px;
}
.contact-form button {
  width: 100%;
}

.menu-title-text {
  font-size: 1.5rem;
  line-height: 28px;
}

.menu-home {
  padding: 2rem 0;
  font-size: 1.5rem;
  line-height: 28px;
  overflow: hidden;
}
.menu-home.has-border {
  border-top: 2px dashed #B4B4B4;
}
.menu-home h4 {
  margin-bottom: 10px;
}
.menu-home a {
  display: block;
  transition: transform ease 150ms;
  transform: scale(1);
}
.menu-home a:hover {
  transform: scale(1.1);
}

.menu-detail .has-bg {
  margin-bottom: 2.5rem;
  position: relative;
}
.menu-detail .has-bg img {
  width: 100%;
}
.menu-detail h2 {
  font-size: 2.5rem;
  position: absolute;
  top: 50%;
  left: 50%;
  color: #fff;
  transform: translate(-50%, -50%);
  padding: 10px;
  text-align: center;
  background: rgba(0, 0, 0, 0.5);
}
.menu-detail-item {
  margin-bottom: 2rem;
}
.menu-detail-item__title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px dashed #B4B4B4;
  font-size: 1.4rem;
  line-height: 28px;
  padding-bottom: 5px;
  margin-bottom: 5px;
}
.menu-detail-item__title h4 {
  margin-right: 8px;
}
.menu-detail-item__title p {
  flex: 0 0 auto;
  text-align: right;
}
.menu-detail-item__desc {
  font-size: 1.1rem;
  line-height: 24px;
}

.navbar-toggler {
  border: none;
}
.navbar-toggler span {
  display: block;
  width: 50px;
  height: 8px;
  background: #fff;
  border-radius: 8px;
  margin-bottom: 5px;
}
.navbar-toggler span:last-child {
  margin-bottom: 0;
}

@media screen and (max-width: 1280px) {
  .index-1 .plate {
    width: 200px;
    height: 422px;
  }
  .index-1 .toast-sandv {
    width: 210px;
    height: 358px;
  }
  .index-1 .ice-cream {
    width: 295px;
    height: 200px;
    position: absolute;
    right: 5%;
    bottom: -3%;
  }
}
@media screen and (max-width: 1024px) {
  .index-menu .mobile-img {
    width: 100%;
  }
  .index-menu .nav-pills {
    justify-content: center;
  }
  .teaser h1 {
    font-size: 3rem;
  }
  .teaser svg {
    width: 100%;
  }
  .gallery a img {
    width: 100%;
  }
  .images {
    display: grid !important;
    grid-template-columns: repeat(2, 1fr);
    flex-wrap: wrap;
    gap: 20px;
  }
  .images img {
    width: 100%;
  }
  .teaser {
    height: 300px;
    padding-top: 30px;
    background-position-x: center !important;
    background-position-y: center !important;
  }
  .teaser h1 {
    font-size: 2.5rem;
    border-bottom: 1px solid #fff;
    padding-bottom: 15px;
  }
  .teaser h1 + svg {
    display: none;
  }
  nav .container {
    width: 100%;
    margin: 0;
    max-width: inherit;
  }
  nav .navbar-collapse {
    position: absolute;
    left: 0;
    width: 100%;
    background: #fff;
    top: 70px;
  }
  nav .navbar-collapse .navbar-nav {
    gap: 0;
    margin-bottom: 0 !important;
  }
  nav .navbar-collapse .nav-link {
    color: #3C3C3B;
    padding: 10px;
    font-size: 1.5rem;
    border-top: 1px solid #3C3C3B;
  }
  .menu-title-text {
    font-size: 1rem;
  }
  .menu-title-text br {
    display: none;
  }
  .menu-home a {
    border: 1px solid #B4B4B4;
    display: flex;
    align-items: center;
    border-radius: 5px;
    flex-direction: row-reverse;
    width: 100%;
    justify-content: flex-end;
    height: 100px;
    margin-bottom: 4rem;
  }
  .menu-home a img {
    width: 130px;
    margin-right: 20px;
    margin-left: 15px;
  }
  .menu-home a h4 {
    margin-bottom: 0;
  }
  .menu-detail h2 {
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
  }
  .menu-detail-item {
    margin-bottom: 1.5rem;
    padding: 0 10px;
  }
  .menu-detail-item__title p {
    font-weight: bold;
  }
  footer .title {
    font-size: 2rem;
  }
  footer .follow-text h3 {
    font-size: 2rem;
  }
  footer .follow-text p {
    line-height: 1.3;
    padding-top: 10px;
  }
  .navbar-brand img {
    width: 125px;
  }
  .teaser-home {
    height: 350px;
  }
  .teaser-home h1 {
    padding-top: 50px;
    font-size: 2rem;
  }
  .teaser-home h1 span {
    font-size: 2.2rem;
  }
  .index-1 {
    height: auto;
  }
  .index-1 .text {
    padding: 20px 0;
    width: 100%;
  }
  .index-1 .text img {
    width: 75%;
  }
  .index-1 .text p {
    font-size: 18px;
    margin-top: 20px;
  }
  .index-1 .toast-sandv, .index-1 .coffee, .index-1 .leaf, .index-1 .plate, .index-1 .ice-cream {
    display: none;
  }
}