.teaser-home {
  background: url("../img/home-teaser.png") no-repeat;
  background-size: cover;
  width: 100%;
  height: 80vh;
  display: flex;

  .container {
    align-items: center;
    justify-content: flex-start;
    display: flex;
  }

  h1 {
    text-align: left;
    color: #fff;
    font-weight: 500;
    font-size: 4.25rem;

    span {
      font-size: 5rem;
    }
  }
}

.teaser {
  width: 100%;
  height: 490px;
  display: flex;
  background-size: cover !important;

  &.teaser-gallery {
    background: url("../img/gallery-teaser.png") no-repeat;
  }

  &.teaser-contact {
    background: url("../img/contact-teaser.png") no-repeat;
  }

  &.teaser-menu {
    background: url("../img/menu-teaser.png") no-repeat;
  }

  .container {
    align-items: center;
    justify-content: center;
    flex-direction: column;
    display: flex;
  }

  h1 {
    color: #fff;
    font-size: 4.25rem;
    margin-bottom: 5px;
  }

  p {
    font-size: 1.5rem;
    text-align: center;
    color: #fff;
    padding-top: 20px;
  }
}

.navbar {
  border-bottom: solid 1px #fff;
  position: absolute;
  width: 100%;

  &-nav {
    gap: 28px;
  }
}

.nav-link {
  font-size: 16px;
  font-weight: 500;
  color: #fff;
}

.index-1 {
  height: 100vh;
  background: #fff9f1;
  overflow: hidden;
  position: relative;

  .coffee {
    background: url("../img/index-anim/coffee.png") no-repeat;
    background-size: cover;
    width: 350px;
    height: 350px;
    position: absolute;
    top: -20%;
    left: 7%;
  }

  .plate {
    background: url("../img/index-anim/plate.png") no-repeat;
    background-size: cover;
    width: 265px;
    height: 558px;
    position: absolute;
    top: 30%;
    left: 0;
  }

  .leaf {
    background: url("../img/index-anim/leaf.png") no-repeat;
    background-size: cover;
    width: 135px;
    height: 101px;
    position: absolute;
    bottom: 0;
    left: 22%;

  }

  .toast-sandv {
    background: url("../img/index-anim/toast.png") no-repeat;
    background-size: cover;
    width: 319px;
    height: 558px;
    position: absolute;
    right: 0;
    top: 0;
  }

  .ice-cream {
    background: url("../img/index-anim/icecream.png") no-repeat;
    background-size: cover;
    width: 495px;
    height: 293px;
    position: absolute;
    right: 10%;
    bottom: -6%;
  }

  .text {
    width: 50%;
    margin: 0 auto;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    p {
      font-size: 1.5rem;
      line-height: 1.43;
      color: $fontColor;
      text-align: center;
    }
  }
}

.nav-pills {
  .nav-link {
    color: $fontColor;
    font-size: 1.5rem;
    line-height: 1.2;

    &.active {
      color: $red !important;
      background-color: transparent !important;
    }
  }
}

footer {
  background: #f8f8f8;
  padding: 3rem 0 0;

  .title {
    gap: 10px;
    font-size: 3rem;
    font-weight: 500;

    color: inherit;
    text-decoration: none;

  }

  .follow-text {
    h3 {
      font-size: 3rem;
      font-weight: 500;
    }

    p {
      font-size: 1rem;
      font-weight: 500;
      line-height: 3.01;

    }
  }
}

.foot {
  padding: 1.5rem 0;
  background: #fff;

  p {
    font-size: 12px;
    line-height: 1.2;
  }
}

address {
  text-align: center;
  padding: 5rem 0;

  p {
    font-size: 1.5rem;
    line-height: 28px;
  }
}

.contact-form {
  width: 100%;
  max-width: 380px;

  .head {
    h4 {
      font-size: 1.5rem;
      line-height: 28px;
    }
  }

  button {
    width: 100%;
  }
}

.menu-title-text {
  font-size: 1.5rem;
  line-height: 28px;
}

.menu-home {
  padding: 2rem 0;
  font-size: 1.5rem;
  line-height: 28px;
  overflow: hidden;

  &.has-border {
    border-top: 2px dashed #B4B4B4;
  }

  h4 {
    margin-bottom: 10px;
  }

  a {
    display: block;
    transition: transform ease 150ms;
    transform: scale(1);

    &:hover {
      transform: scale(1.1);
    }
  }
}

.menu-detail {
  .has-bg {
    margin-bottom: 2.5rem;
    position: relative;
    img {
      width: 100%;
    }
  }
  h2 {
    font-size: 2.5rem;
    position: absolute;
    top: 50%;
    left: 50%;
    color: #fff;
    transform: translate(-50%, -50%);
    padding: 10px;
    text-align: center;
    background: rgb(0,0,0,.5);
  }

  &-item {
    margin-bottom: 2rem;

    &__title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 2px dashed #B4B4B4;
      font-size: 1.4rem;
      line-height: 28px;
      padding-bottom: 5px;
      margin-bottom: 5px;

      h4 {
        margin-right: 8px;
      }

      p {
        flex: 0 0 auto;
        text-align: right;
      }
    }

    &__desc {
      font-size: 1.1rem;
      line-height: 24px;
    }
  }
}


.navbar-toggler {
  border: none;
  span {
    display: block;
    width: 50px;
    height: 8px;
    background: #fff;
    border-radius: 8px;
    margin-bottom: 5px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

@media screen and (max-width: 1280px) {
  .index-1 {
    .plate {
      width: 200px;
      height: 422px;
    }


    .toast-sandv {
      width: 210px;
      height: 358px;
    }

    .ice-cream {
      width: 295px;
      height: 200px;
      position: absolute;
      right: 5%;
      bottom: -3%;
    }
  }
}

@media screen and (max-width: 1024px) {
  .index-menu {
    .mobile-img {
      width: 100%;
    }
    .nav-pills {
      justify-content: center;
    }
  }
  .teaser {
    h1 {
      font-size: 3rem;
    }

    svg {
      width: 100%;
    }
  }
  .gallery {
    a img {
      width: 100%;
    }
  }
  .images {
    display: grid !important;
    grid-template-columns: repeat(2, 1fr);
    flex-wrap: wrap;
    gap: 20px;

    img {
      width: 100%;
    }
  }
  .teaser {
    height: 300px;
    padding-top: 30px;
    background-position-x: center !important;
    background-position-y: center !important;
    h1 {
      font-size: 2.5rem;
      border-bottom: 1px solid #fff;
      padding-bottom: 15px;
      & + svg {
        display: none;
    }

    }
  }
  nav {
    .container {
      width: 100%;
      margin: 0;
      max-width: inherit;
    }

    .navbar-collapse {
      position: absolute;
      left: 0;
      width: 100%;
      background: #fff;
      top: 70px;

      .navbar-nav {
        gap: 0;
        margin-bottom: 0 !important;
      }

      .nav-link {
        color: $fontColor;
        padding: 10px;
        font-size: 1.5rem;
        border-top: 1px solid $fontColor;
      }
    }
  }
  .menu-title-text {
    font-size: 1rem;

    br {
      display: none;
    }
  }
  .menu-home {
    a {
      border: 1px solid #B4B4B4;
      display: flex;
      align-items: center;
      border-radius: 5px;
      flex-direction: row-reverse;
      width: 100%;
      justify-content: flex-end;

      height: 100px;
      margin-bottom: 4rem;

      img {

        width: 130px;
        margin-right: 20px;
        margin-left: 15px;

      }

      h4 {
        margin-bottom: 0;
      }
    }
  }

  .menu-detail  {
    h2 {
      font-size: 2.5rem;
      margin-bottom: 1.5rem;
    }
  }
  .menu-detail-item {
    margin-bottom: 1.5rem;
    padding: 0 10px;
    &__title {
     p {
      font-weight: bold;
     }
    }
  }
  footer {
    .title {
      font-size: 2rem;
    }
    .follow-text {
      h3 {
        font-size: 2rem;
      }
      p {
          line-height: 1.3;
        padding-top: 10px;
      }
    }
  }
  .navbar-brand {
    img {
      width: 125px;
    }
  }

  .teaser-home {
    height: 350px;
    h1 {
      padding-top: 50px;
      font-size: 2rem;
      span {
        font-size: 2.2rem;
      }
    }
  }

  .index-1 {
    height: auto;
    .text {
      padding: 20px 0;
      width: 100%;
      img {
        width: 75%;
      }
      p  {
        font-size: 18px;
        margin-top: 20px;
      }
    }

    .toast-sandv, .coffee, .leaf, .plate, .ice-cream {
      display: none;
    }
  }
}